import React from 'react';

export default function Resume() {
  return (
    <div className="pt-3 text-center">
      <a className="" download="John-Daniel-Yates" href="/assets/images/John-Daniel-Yates.pdf">
       <img src="https://i.imgur.com/D2wCysN.png" title="source: imgur.com" className='img-fluid img-thumbnail'></img></a>
    </div>
  );
}
